import { styled } from 'Theme/stitches.config';
import { StyledButtonLabel } from 'DesignComponents/Atoms/Buttons/ButtonLabel/BaseButtonLabelStyle';
import { StitchesVariantProps } from 'Shared/Types/types';
import { BaseProps } from './BaseButtonType';
import KexIconLoader, { IconName } from 'DesignSystem/Icons/KexIconLoader';

type StyledButtonVariants = StitchesVariantProps<typeof Root>;

export type TertiaryButtonType = BaseProps & {
  color: StyledButtonVariants['color'];
  icon?: IconName;
  inverse?: boolean;
  size?: StyledButtonVariants['size'];
};

function TertiaryButton({
  children,
  color = 'Regular',
  disabled,
  focus,
  icon,
  inverse,
  pressed,
  size,
  text,
  hug,
}: TertiaryButtonType) {
  const Icon = icon && KexIconLoader(icon);
  return (
    <Root
      color={color}
      size={size}
      hug={hug}
      {...(disabled && { disabled })}
      {...(pressed && { pressed })}
      {...(focus && { focus })}
      {...(inverse && { inverse })}
    >
      {children}
      {Icon && (
        <Icon color={color === 'Inverse' ? 'primary' : 'inverse'} size="s" />
      )}
      <StyledButtonLabel hover size={size}>
        {text}
      </StyledButtonLabel>
    </Root>
  );
}

const Root = styled('div', {
  display: 'flex',
  cursor: 'pointer',
  w: 'fit-content',
  py: '$s75',
  px: '$s150',
  [`& ${StyledButtonLabel}`]: {
    textDecoration: 'underline',
  },
  '&:focus-visible': {
    outline: 'unset !important',
  },
  variants: {
    color: {
      Regular: {
        [`& ${StyledButtonLabel}`]: {
          color: '$onInteractiveSecondary',
        },
        '&:hover': {
          background: '$interactiveSecondaryBackgroundHover',
        },
      },
      Inverse: {
        [`& ${StyledButtonLabel}`]: {
          color: '$onInteractivePrimary',
        },
        '&:hover': {
          background: '$interactivePrimaryBackgroundHover',
        },
      },
    },
    size: {
      s: {
        p: '$s75',
        g: '$s50',
      },
      m: {},
    },
    disabled: {
      true: {
        backgroundColor: '$interactiveDisabled_1',
        borderWidth: 0,
        borderColor: 'unset',
        [`& ${StyledButtonLabel}`]: {
          color: '$interactiveDisabled_2',
          textDecoration: 'none',
        },
      },
    },
    inverse: {
      true: {
        flexDirection: 'row-reverse',
      },
    },
    pressed: {
      true: {},
    },
    focus: {
      true: { boxShadow: '0px 0px 0px 2px $colors$interactiveBorderActive' },
    },
    hug: {
      height: {
        h: '100%',
      },
      width: {
        w: '100%',
      },
    },
  },
  compoundVariants: [
    {
      disabled: true,
      color: 'Inverse',
      css: {
        [`& ${StyledButtonLabel}`]: {
          color: '$interactiveDisabled_1',
        },
        backgroundColor: '$interactiveDisabled_2',
      },
    },
    {
      pressed: true,
      color: 'Regular',
      css: {
        background: '$interactiveSecondaryBackgroundPressed',
        '&:hover': {
          background: '$interactiveSecondaryBackgroundPressed',
        },
      },
    },
    {
      pressed: true,
      color: 'Inverse',
      css: {
        background: '$interactivePrimaryBackgroundPressed',
        '&:hover': {
          background: '$interactivePrimaryBackgroundPressed',
        },
      },
    },
    {
      focus: true,
      color: 'Inverse',
      css: {
        boxShadow: '0px 0px 0px 2px $colors$interactiveBorderActiveInverse',
      },
    },
    {
      hug: 'width',
      css: {
        justifyContent: 'center',
      },
    },
  ],
});

export default TertiaryButton;
